// newsletter signup submission
export function signUpFormHandler() {
  const form = document.querySelector('.footer-newsletter-form form');
  form.addEventListener('submit', (e) => {
    e.preventDefault();
    const email = e.target.querySelector('input[name="email"]').value;
    const data = {
      'fields': [
        {
          'name': 'email',
          'value': email
        }
      ]
    };
    fetch(e.target.action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        const newsletterMsg = document.querySelector('.footer-newsletter-msg');
        if (response.ok) {
          newsletterMsg.style.display = 'block';
          newsletterMsg.innerText = 'thank you for inviting us into your inbox!';
          form.style.display = 'none';
          setTimeout(() => {
            e.target.querySelector('input[name="email"]').value = '';
            newsletterMsg.style.display = 'none';
            form.style.display = 'block';
          }, 3000);
        } else {
          newsletterMsg.style.display = 'block';
          newsletterMsg.innerText = 'somethign went wrong. please try again.';
          form.style.display = 'none';
          setTimeout(() => {
            e.target.querySelector('input[name="email"]').value = '';
            newsletterMsg.style.display = 'none';
            form.style.display = 'block';
          }, 3000);
        }
      })
  });
}