/**
 * @class modal
 * @param {string} modalId - The id of the element without the hash
 * @description
 * This class is used to create a modal element.
 * It exposes a modal class on the cletile object in the window element
 * 
 *  It is used in the following way:
 *  document.addEventListener('DOMContentLoaded', () => {
 *    new window.cletile.module.modal('my-modal')
 *  })
 */
export class modal {
  constructor(modalId) {
    this.modalEl = document.getElementById(modalId)
    this.closeEl = this.modalEl.querySelector('.js-close')
    
    // Create Listeners
    this. closeEl.addEventListener('click', () => {
      this.modalEl.close()
    })
  };
}
