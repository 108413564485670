import { signUpFormHandler } from "../scripts/main/newsletter-signup";
import { drawer } from "../scripts/main/drawer";
import { modal } from "../scripts/main/modal";
import { getHubspotFormBody } from "../utils/utilities";
import "../scripts/main/flip-clock";
import "../scripts/main/notifications-manager";

window.cletile = {}
window.cletile.module = { drawer, modal };
/**
 * TODO:
 *  Remove this after form logic that uses this util is moved into its own JS file.
 *  Check ./sections/cle-modal-newsletter-signup.liquid
 */
window.cletile.utils = { getHubspotFormBody }

document.addEventListener("DOMContentLoaded", () => {
  signUpFormHandler();
});
